import React from "react";
import { Container, Col, Row } from "react-bootstrap";

const HeroBusiness = (props) => {
    return (
        <div className="dark-bg hero-section">
            <Container>
                <Row>
                    <Col sm={12} className="mb-80 mt-80">
                        <h1>You doing business?<br />Count on you for reliability.</h1>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default HeroBusiness;
