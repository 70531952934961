import React, { useContext, useEffect } from "react"
import GlobalContext from "../context/GlobalContext";
import Seo from "../components/Seo";
import { findUtmParams, findOfferParams } from "../utils/helperFn";
import CheckAvailability from "../components/Rebrand/CheckAvailability/CheckAvailability";
import HeroBusiness from "../components/Rebrand/Hero/HeroBusiness";
import YouPhoneBusiness from "../components/Rebrand/YouPhone/YouPhoneBusiness";
import BusinessFeatures from "../components/Rebrand/BusinessFeatures/BusinessFeatures";
import ContactBusiness from "../components/Rebrand/Contact/ContactBusiness";
import DynamicPricingSection from "../sections/PricingSection/DynamicPricingSection"
import Speed from "../components/Rebrand/Speed/Speed";
import { Container, Col, Row } from "react-bootstrap";
import { Section } from "../components/Core";

const IndexPage = (props) => {
    const gContext = useContext(GlobalContext)

    if (findUtmParams(props.location.search)) {
        if (typeof window !== 'undefined' && window) {
            localStorage.setItem('utmData', JSON.stringify(findUtmParams(props.location.search)));
        }
    }

    useEffect(() => {
        localStorage.removeItem('registrationData')
        if (findOfferParams(props.location.search)) {
            gContext.goSetRegistrationData({
                offerData: findOfferParams(props.location.search)
            });
        } else {
            gContext.goSetRegistrationData(null);
        }
    }, [])

    return <>
        <Seo page={'business'} />
        <HeroBusiness />
        <Section className="pb-0">
            <Container>
                <Row>
                    <Col sm={12} className="text-center">
                        <h2>Plans that work harder for you</h2>
                    </Col>
                </Row>
            </Container>
        </Section>
        <div className="pt-5 pb-5">
            <DynamicPricingSection
                location={'business'}
                searchParams={props.location.search}
            />
        </div>
        <BusinessFeatures />
        <Speed />
        <ContactBusiness />
        <CheckAvailability center bg="green-bg" title={'Are we right up your street?'} />
    </>;
}

export default IndexPage