import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Section } from "../../Core";

import image1 from './../../../assets/image/rebrand/svg/empowering.svg'
import image2 from './../../../assets/image/rebrand/svg/specialist.svg'
import image3 from './../../../assets/image/rebrand/svg/youmesh.svg'

const BusinessFeatures = (props) => {
    return (
        <Section className="green-bg">
            <Container>
                <Row>
                    <Col sm={12} md={4} className="text-center">
                        <img src={image1} width={140} className="mb-3"/>
                        <h4 className="mb-3">Empowering<br/>business</h4>
                        <p>Full Fibre Internet<br />for every business type</p>
                    </Col>
                    <Col sm={12} md={4} className="text-center">
                        <img src={image2} width={140} className="mb-3"/>
                        <h4 className="mb-3">Specialist<br/>support</h4>
                        <p>Monday to Saturday<br />8am-8pm</p>
                    </Col>
                    <Col sm={12} md={4} className="text-center">
                        <img src={image3} width={140} className="mb-3"/>
                        <h4 className="mb-3">YOUMESH<br/>&nbsp;</h4>
                        <p>Complete Wi-Fi coverage<br />of your entire<br />office space</p>
                    </Col>
                </Row>
            </Container>
        </Section>
    )
};

export default BusinessFeatures;
